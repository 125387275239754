import {useAppSelector} from "../../store";
import {selectRole} from "./authSlice";
import {Navigate} from "react-router-dom";
import {FC, ReactNode} from "react";

interface SuperAdminRequiredProps {
    children: ReactNode;
}

export const SuperAdminRequired:FC<SuperAdminRequiredProps> = ({children}) => {
    const role = useAppSelector(selectRole);

    if(!role || role !== 'super_admin') {
        return <Navigate to="/admin" replace />
    }

    return <>{children}</>;
}
