import {theme} from "./services/theme/theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Navigation} from "./navigation";
import React, {useEffect} from "react";
import {useGetFlightPriceQuery} from "./services/redux/admin/reservations/api";
import {useDispatch} from "./services/redux/store";
import {setBasePrice, setPrivateLargePrice, setPrivateSmallPrice} from "./services/redux/slices/auth/authSlice";


export const SetupPrice = () => {
    const dispatch = useDispatch();
    const {data} = useGetFlightPriceQuery();

    // console.log('data',data);

    useEffect(() => {
        if(data) {
            dispatch(setBasePrice(Number(data.base_price)))
            dispatch(setPrivateLargePrice(Number(data.private_large_price)))
            dispatch(setPrivateSmallPrice(Number(data.private_sm_price)))
        }
    },[data]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navigation />
        </ThemeProvider>
    )
}
