import React from 'react';

import {ReduxProvider} from "./services/redux/Provider";
import {SetupPrice} from "./SetupPrice";

function App() {
  return (
        <ReduxProvider>
            <SetupPrice />
        </ReduxProvider>
  );
}

export default App;
