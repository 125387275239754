import {createSlice, Slice, SliceCaseReducers} from "@reduxjs/toolkit";
import {RootState} from "../../store";



export const name = 'auth';

export type userRole = 'super_admin' | 'admin';

export interface authState {
    accessToken: string;
    role: userRole;
    base_price: number;
    private_large_price: number;
    private_sm_price: number;
}

const initialState: authState = {
    accessToken: '',
    role: 'super_admin',
    base_price: 0,
    private_large_price: 0,
    private_sm_price: 0,
};

export const authSlice: Slice<
    authState,
    SliceCaseReducers<authState>
> = createSlice<authState, SliceCaseReducers<authState>>({
    name,
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setBasePrice: (state, action) => {
            state.base_price = action.payload;
        },
        setPrivateLargePrice: (state, action) => {
            state.private_large_price = action.payload;
        },
        setPrivateSmallPrice: (state, action) => {
            state.private_sm_price = action.payload;
        },
    },
});

export const {
    setToken,
    setRole,
    setBasePrice,
    setPrivateLargePrice,
    setPrivateSmallPrice,
} = authSlice.actions;

export const selectToken = (state: RootState) =>
    state.auth.accessToken;

export const selectRole = (state: RootState) =>
    state.auth.role;

export const selectBasePrice = (state: RootState) =>
    state.auth.base_price;
export const selectPrivateLargePrice = (state: RootState) =>
    state.auth.private_large_price;
export const selectPrivateSmallPrice = (state: RootState) =>
    state.auth.private_sm_price;

export const selectIsAuthorized = (state: RootState) =>
    Boolean(selectToken(state));
